<template>
<h1><slot/></h1>
</template>
<style scoped>
h1 {
    font-family: Palatino, serif;
    font-size: 16px;
    color: #0080a8;
    letter-spacing: 0.18em;
}
</style>